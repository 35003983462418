import React from 'react';
import { graphql } from 'gatsby';
import { Layout } from '@components';
import { MDXRenderer } from 'gatsby-plugin-mdx';

interface PageProps {
    data: any;
    pageContext: { lang: string; en: string };
}
const Page: React.FC<PageProps> = ({ data, pageContext: { lang } }) => {
    const {
        slug,
        Title,
        Content: {
            data: {
                childMdx: { body }
            }
        }
    } = data.strapiPage;

    // const Title = en
    //     ? data.strapiPage.localizations.data[0]?.attributes.Title
    //     : data.strapiPage.Title;
    // const body = en
    //     ? data.strapiPage.childStrapiPageEnContent.childMdx.body
    //     : data.strapiPage.Content.data.childMdx.body;
    return (
        <Layout className="px-8" title={Title} lang={lang} path={slug}>
            <h1 className="contentBlock">{Title}</h1>
            <div className={`pb-8 page ${slug == 'about' && 'bigger'}`}>
                <MDXRenderer>{body}</MDXRenderer>
            </div>
        </Layout>
    );
};
export const query = graphql`
    query PageQuery($id: String!, $locale: String!) {
        strapiPage(id: { eq: $id }, locale: { eq: $locale }) {
            slug
            Title
            Content {
                data {
                    childMdx {
                        body
                    }
                }
            }
        }
    }
`;
export default Page;
